//next button
.react-simple-image-viewer__next,.styles-module_navigation__1pqAE{
    width: 50px !important;  
    height: 50px !important;
    right:100px !important;
    border-radius: 25px !important; 
    opacity: 1 !important;
    &:hover{
        background-color: #ffbf00 !important; 
    }
}
.styles-module_prev__KqFRp{
    left:100px;
    display:flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.styles-module_navigation__1pqAE{
    width:50px !important;
    height:50px !important;
    display:flex !important;
    justify-content: center !important;
    align-items: center !important;
    font-size: 35px;
}

//cancel button
.styles-module_close__2I1sI{
    width:50px !important;
    height:50px !important;
    font-size: 50px !important;
    display:flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-left: 0 !important;
    right:100px !important;
    border:none !important;
    &:hover{
        color: rgb(251, 2, 2) !important; 
    }
}

.carousel-main-container{
    max-width: 600px;
    height: 150px;
    display: flex;
    justify-content: flex-start;
    align-items: start;
    overflow-x: scroll;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;
    border-radius: 2px;
    .image-container {
        display: flex;
        gap: 10px;
        height:150px;
      }
      .image-wrapper {
        width: 150px;
        height: 150px;
        display: flex;
        align-items: start;
        cursor: pointer;
        transition: transform 0.3s ease-in-out;
        box-shadow: 0px 4px 10px rgba(255, 255, 255, 0.1);
        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: transform 0.3s ease-in-out;
      
            &:hover {
              transform: scale(1.1);
            }
          }
      }
      .image-viewer {
        border: 2px solid;
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 0, 0, 0.8); // Optional for overlay effect
      }
}

.delete-button {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
    background-color: #ff4d4d; /* Red for delete action */
    color: white;
    border: none;
    padding: 6px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .delete-button:hover {
    background-color: #cc0000; /* Darker red on hover */
  }
  
  .delete-button:active {
    transform: scale(0.95); /* Slight shrink effect on click */
  }
  