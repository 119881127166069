.searchButton {
  width: 211px;
  height: 40px;
  background-color: #FFFFFF !important;
  color: black !important;
  cursor: pointer;
  border: none !important;
  border-radius: 5px;
  padding: 8px 12px;
  box-shadow: none !important; /* Removes unwanted shadow */
  outline: none !important; /* Removes focus outline */
  
  &:hover,
  &:focus,
  &:active {
    color: black !important;
    background-color: #FFFFFF !important;
    box-shadow: none !important;
    outline: none !important;
  }
}

/* Removes default Bootstrap caret (down arrow) */
.dropdown-toggle::after {
  display: none !important;
}

/* Styles the dropdown menu */
.dropdown-menu {
  border: none !important;
  box-shadow: none !important; /* Removes Bootstrap default shadow */
}

/* Styles each dropdown item */
.dropdown-item {
  padding: 8px 12px !important;
  color: black !important;
  
  &:hover,
  &:focus {
    background-color: #f0f0f0 !important;
    color: black !important;
  }
}
