@import "../.././index.scss";

.panelControl {
    margin-top: 20px;
    height: 40px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1100px) {
        flex-direction: column;
        gap: 16px;
    }

    h1 {
        font-size: 20px;
        line-height: 24px;
        font-weight: 500;
    }
}

.panelSub{
    display:flex;
    gap:16px;
    height:40px;
    align-items: center;
}
.contentWrapper{
    width: 100%;
    margin-top:20px;
    background-color:white;
    padding:16px 32px;
    margin-bottom:50px;
}
.searchContainer{
    width:300px;
    border:1px solid #ADB4D2;
    height:40px;
    background-color: #FFF;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap:5px;
    padding-left:5px;
    input{
        border:none;
        width:100%;
        height:100%;
        outline:none;
        background: none;
    }
}
.addVehicleButton{
    width:211px;
    height:100%;
    background-color: #ffbf00;
    color:white;
    cursor: pointer ;
    border:none;
    border-radius: 5px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:8px;
    &:hover{
        color:white;
        background-color: #e7ad02;
    }
  }
  .cancelButton{
    width:86px;
    height:100%;
    border:1px solid#E3E6EF;
    color:#9299B8;
    background-color: #ffff;
    cursor: pointer ;
    border:none;
    border-radius: 5px;
    font-weight: 500;
    line-height:16px;
    display:flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap:8px;
}

.tableContainer{
    margin-top:50px;
    height:80vh;
}

.accordianHeader{
    font-family: $fontFamily;
    font-weight:500;
    font-size: 14px;
    line-height: 20px;
}
.accordianBody{
    height:200px;
    margin-bottom: 10px;
}
.formControl{
    display: flex;
    flex-direction: column;
    gap:5px;
    .formGroup{
        display:flex;
        flex-direction: row;
        gap:10px;
        height:50px;
        .upload {
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #dddddd;
            color: white;
            border-radius: 5px;
            cursor: pointer;
            padding: 10px 20px;
            font-size: 16px;
            font-weight: bold;          
            input {
              display: none;
            }
          }
          
        label{
            width:300px;
            color:#5A5F7D;
            font-family: $fontFamily;
            font-weight:500;
            font-size: 14px;
            line-height: 20px;

        }
        input{
            width:300px;
        }
        select{
            width:300px;
            cursor: pointer;
        }
        .checkBox{
            width:15px;
        }
        textarea {
            width: 100%;
            max-width: 700px;
            height: 120px;
            padding: 12px;
            font-size: 16px;
            font-family: $fontFamily;
            border: 2px solid #CBD5E1;
            border-radius: 8px;
            background-color: #f9f9f9;
            color: #333;
            outline: none;
            resize: none;
        }
        span{
            width:300px;
            border:1px solid #CBD5E1;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 10px;
            border-radius: 8px;
        }
        .report{
            width: 100%;
            max-width: 800px;
            height: 120px;
            padding: 12px;
            font-size: 16px;
            font-family: $fontFamily;
        }
    }
    .formCheckBox{
        display:flex;
        flex-direction: row;
        gap:10px;
        height:50px;
        div{
            display:flex;
            align-items: center;
            justify-content: center;
            gap:6px;
            label{
                width:100px;
                color:#5A5F7D;
                font-family: $fontFamily;
                font-weight:500;
                font-size: 14px;
                line-height: 20px;
            }
            input{
                width:25px;
            }
        }
    }
}
.carouselContainer{
    width:900px;
    height:100%;
}